import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { AuthenticatedState } from "./auth-provider.types";
import { User, getAuth } from "@firebase/auth";
import { firebaseApp } from "../../firebase";
import { UserEntity } from "../../types/entities";
import { doc, getDoc, getFirestore } from "@firebase/firestore";

type AuthProviderProps = {
    children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({
    children,
}) => {

    // state
    const [authenticatedState, setAuthenticatedState] = useState<AuthenticatedState>('authenticating')
    const [user, setUser] = useState<User | null>(null)
    const [userEntity, setUserEntity] = useState<UserEntity | null>(null)

    // side effects
    useEffect(() => {
        const auth = getAuth(firebaseApp)
        auth.onAuthStateChanged((user) => {
            if (!user) {
                setAuthenticatedState('not-authenticated')
                setUser(null)
                return;
            }

            setAuthenticatedState('authenticated')
            setUser(user)
        })
    }, [])

    useEffect(() => {
        if (!user?.uid) {
            setUserEntity(null)
            return;
        }
        const db = getFirestore(firebaseApp)
        getDoc(doc(db, `users/${user.uid}`)).then((newUserEntitySnapshot) => {
            const newUserEntity = newUserEntitySnapshot.data()
            setUserEntity(newUserEntity as UserEntity)
        })
    }, [user?.uid])

    return (
        <AuthContext.Provider value={{
            authenticatedState,
            user,
            userEntity,
        }}>
            {children}
        </AuthContext.Provider>
    )
}

type AuthContextType = {
    authenticatedState: AuthenticatedState
    user: User | null,
    userEntity: UserEntity | null,
}

export const AuthContext = createContext<AuthContextType>({
    authenticatedState: 'authenticating',
    user: null,
    userEntity: null,
})