import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from "react";
import { ViewContainer } from "../../components/view-container/view-container";
import { LettersNavBar } from "../../components/letters-nav-bar/letters-nav-bar";
import { Alert, Button, Input } from "antd";
import "./letters-challenge.css"
import { getFunctions, httpsCallable } from "@firebase/functions";
import { firebaseApp } from "../../firebase";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import { SignInView } from "../sign-in-view/sign-in-view";
// import { LettersDisclaimer } from "./subcomponents/letters-disclaimer/letters-disclaimer";

export const LettersChallenge: FC = () => {

    // state
    const [guess, setGuess] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [guessFailed, setGuessFailed] = useState<boolean>(false)
    // const [shouldGuess, setShouldGuess] = useState<boolean>(false)
    const [signIn, setSignIn] = useState<boolean>(false)

    // event handlers
    const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setGuess(event.target.value)
    }, [])

    const handleFormSubmit = useCallback(async (event: FormEvent) => {
        if (guess.toLowerCase() === 'login') {
            setSignIn(true)
            return;
        }

        event.preventDefault()
        setIsLoading(true)
        setGuessFailed(false)

        const functions = getFunctions(firebaseApp)
        const callable = httpsCallable(functions, 'checkChallenge')
        const response = await callable({
            guess,
        })

        if (!(response.data as any).success) {
            setGuessFailed(true)
        } else {
            const {
                username,
                password,
            } = response.data as { username: string, password: string }

            const auth = getAuth(firebaseApp)
            await signInWithEmailAndPassword(auth, username, password)
        }

        setIsLoading(false)
    }, [guess])

    // const handleContinue = useCallback(() => {
    //     setShouldGuess(true)
    // }, [])

    useEffect(() => {
        document.title = 'Letters are private'
    }, [])

    if (signIn) return <SignInView />
    // if (!shouldGuess) return <LettersDisclaimer onContinue={handleContinue} />

    return (
        <ViewContainer>
            <LettersNavBar />
            <form onSubmit={handleFormSubmit}>
                <div className="letters-challenge-container">
                    <h1 style={{ marginBottom: 24 }}>Just to make sure you're you</h1>
                    <p>We once watched a movie together in a fort you built.</p>
                    <p>We spent most of the movie joking about what the main character's name was (I'm still not convinced you believe me).</p>
                    <p>What's the name of the movie?</p>
                    <Input
                        placeholder="e.g. the eras tour (taylor's version)"
                        style={{ marginBottom: 12, marginTop: 12 }}
                        value={guess}
                        onChange={handleInputChange}
                        disabled={isLoading}
                    />
                    <Button
                        block
                        disabled={!guess}
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        Continue
                    </Button>
                    {guessFailed && (
                        <Alert
                            type="warning"
                            description={<>Whoops that's not it. Feel free to try again.<br /><br />Please don't guess if you don't think this is meant for you.</>}
                            style={{ marginTop: 24 }}
                        />
                    )}
                </div>
            </form>
        </ViewContainer>
    )
}