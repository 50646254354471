import { getFirestore, getDocs, query, collection, where, limit, updateDoc, Timestamp } from "@firebase/firestore"
import { firebaseApp } from "../../../../../../firebase"

class LetterDatePickerService {
    public async changeDate(letterNumber: number, date: Date): Promise<void> {
        const db = getFirestore(firebaseApp)
        const docs = await getDocs(query(collection(db, 'letters'), where('number', '==', letterNumber), limit(1)))
        const letterDoc = docs.docs[0]
        await updateDoc(letterDoc.ref, {
            date: Timestamp.fromDate(date),
        })
    }
}

export const service = new LetterDatePickerService()