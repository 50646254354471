import { FC, useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { AuthContext } from "../auth-provider/auth-provider";
import { AuthenticatedRouter, AuthenticatingRouter, UnauthenticatedRouter } from "./app-router.routes";

export const AppRouter: FC = () => {

    // context
    const { authenticatedState } = useContext(AuthContext)

    if (authenticatedState === 'authenticating') return <RouterProvider router={AuthenticatingRouter} />;
    if (authenticatedState === 'not-authenticated') return <RouterProvider router={UnauthenticatedRouter} />
    return <RouterProvider router={AuthenticatedRouter} />
}