import { FC, useCallback } from "react";
import { AutoCollapseButton } from "../../../auto-collapse-button/auto-collapse-button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

export const LettersButton: FC = () => {

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleButtonClick = useCallback(() => {
        navigate('/letters')
    }, [navigate])

    return (
        <AutoCollapseButton
            onClick={handleButtonClick}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
            Back to Letters
        </AutoCollapseButton>
    )
}