import { FC, useCallback, useContext, useState } from "react";
import { AutoCollapseButton } from "../../../../../auto-collapse-button/auto-collapse-button";
import { faLock, faUnlock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LetterPageContext } from "../../../../letter-page.context";
import { service } from "./private-button.service";

export const PrivateButton: FC = () => {

    // context
    const { letter, onRefresh } = useContext(LetterPageContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(async () => {
        setIsLoading(true)
        if (letter?.isPrivate) {
            await service.makeLetterPublic(letter!.number)
        } else {
            await service.makeLetterPrivate(letter!.number)
        }
        await onRefresh()
        setIsLoading(false)
    }, [letter, onRefresh])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon
            icon={letter?.isPrivate ? faUnlock : faLock} />}
            onClick={handleButtonClick}
            loading={isLoading}
        >
            {letter?.isPrivate ? 'Unlock' : 'Lock'}
        </AutoCollapseButton>
    )
}