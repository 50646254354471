import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useState } from "react";
import { AutoCollapseButton } from "../../../../components/auto-collapse-button/auto-collapse-button";
import { useNavigate } from "react-router-dom";
import { service } from "./write-letter-button.service";

export const WriteLetterButton: FC = () => {

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleWriteLetterClick = useCallback(async () => {
        setIsLoading(true)
        const newLetterNumber = await service.createLetter()
        navigate(`/letters/${newLetterNumber}?edit=true`)
        setIsLoading(false)
    }, [navigate])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon icon={faPencil} />}
            onClick={handleWriteLetterClick}
            loading={isLoading}
        >
            Write
        </AutoCollapseButton>
    )
}