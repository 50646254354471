import { FC, ReactNode } from "react";
import './view-container.css';

type ViewContainerProps = {
    children: ReactNode
}

export const ViewContainer: FC<ViewContainerProps> = ({
    children,
}) => {
    return (
        <div className="view-container">
            {children}
        </div>
    )
}