import { HttpsCallableResult, getFunctions, httpsCallable } from "@firebase/functions";
import { LetterContentEntity, LetterEntity } from "../../types/entities";
import { firebaseApp } from "../../firebase";
import { Timestamp } from "@firebase/firestore";

class SharedLetterViewService {
    public async getSharedLetter(sharedLetterId: string): Promise<{ letter: LetterEntity, letterContents: LetterContentEntity }> {
        const functions = getFunctions(firebaseApp)
        const getSharedLetter = httpsCallable(functions, 'getSharedLetter')
        const {
            data: {
                success,
                letter,
                letterContents,
            }
        } = await getSharedLetter(sharedLetterId) as HttpsCallableResult<{ success: boolean, letter: LetterEntity & { date: { _seconds: number, _nanoseconds: number } }, letterContents: LetterContentEntity }>

        if (!success) throw new Error();

        const transformedLetter = {
            ...letter,
            date: new Timestamp(letter.date._seconds, letter.date._nanoseconds),
        } as LetterEntity

        return {
            letter: transformedLetter,
            letterContents,
        }
    }
}

export const service = new SharedLetterViewService();