import { FC, useCallback, useEffect, useState } from "react";
import { LetterContentEntity, LetterEntity } from "../../types/entities";
import { LetterPage } from "../../components/letter-page/letter-page";
import { service } from "./shared-letter-view.service";
import { useLocation } from "react-router-dom";
import { ViewContainer } from "../../components/view-container/view-container";
import { LettersNavBar } from "../../components/letters-nav-bar/letters-nav-bar";
import { LinkDoesNotWork } from "./subcomponents/link-does-not-work/link-does-not-work";

type SharedLetterViewProps = {
    sharedLetterId: string
}

export const SharedLetterView: FC<SharedLetterViewProps> = ({
    sharedLetterId,
}) => {

    // router hooks
    const { pathname } = useLocation();

    // state
    const [letter, setLetter] = useState<LetterEntity | null>(null)
    const [letterContents, setLetterContents] = useState<LetterContentEntity | null>(null)
    const [didError, setDidError] = useState<boolean>(false)

    // event handlers
    const handleRefresh = useCallback(async () => {

        let result;
        try {
            result = await service.getSharedLetter(sharedLetterId)
        } catch {
            setDidError(true)
            return
        }
        
        setLetter(result.letter)
        setLetterContents(result.letterContents)
    }, [sharedLetterId])

    // side effects
    useEffect(() => {
        handleRefresh()
    }, [handleRefresh])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (didError) return (
        <ViewContainer>
            <LettersNavBar />
            <LinkDoesNotWork />
        </ViewContainer>
    )

    return (
        <LetterPage
            letter={letter}
            letterContents={letterContents}
            onRefresh={handleRefresh}
        />
    )
}