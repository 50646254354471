import { FC, useCallback, useContext, useEffect, useState } from "react";
import "./letter-navigation.css";
import { LetterEntity } from "../../../../types/entities";
import { LetterPageContext } from "../../letter-page.context";
import { QueryConstraint, collection, getDocs, getFirestore, limit, orderBy, query, where } from "@firebase/firestore";
import { AuthContext } from "../../../auth-provider/auth-provider";
import { LetterNavButton } from "./subcomponents/letter-nav-button/letter-nav-button";

export const LetterNavigation: FC = () => {

    // context
    const { userEntity } = useContext(AuthContext)
    const { letter } = useContext(LetterPageContext)
    
    // state
    const [prevLetter, setPrevLetter] = useState<LetterEntity | null>(null)
    const [nextLetter, setNextLetter] = useState<LetterEntity | null>(null)

    // data fetchers
    const getLetters = useCallback(async () => {
        setPrevLetter(null)
        setNextLetter(null)

        if (letter?.number === undefined) return;

        const db = getFirestore()
        const nextLetterConstraints: QueryConstraint[] = [
            where('number', ">", letter?.number),
            orderBy('number', 'asc'),
            limit(1),
        ]
        const prevLetterConstraints: QueryConstraint[] = [
            where('number', '<', letter?.number),
            orderBy('number', 'desc'),
            limit(1),
        ]

        if (userEntity?.role === 'reader') {
            nextLetterConstraints.push(
                where('isPublished', '==', true),
            )
            prevLetterConstraints.push(
                where('isPublished', '==', true),
            )
        }

        const nextLetterQuerySnapshot = await getDocs(query(collection(db, 'letters'), ...nextLetterConstraints))
        const prevLetterQuerySnapshot = await getDocs(query(collection(db, 'letters'), ...prevLetterConstraints))

        setNextLetter(nextLetterQuerySnapshot.docs[0]?.data() as LetterEntity || null)
        setPrevLetter(prevLetterQuerySnapshot.docs[0]?.data() as LetterEntity || null)
    }, [letter?.number, userEntity?.role])

    // side effects
    useEffect(() => {
        getLetters()
    }, [getLetters])

    return (
        <div className="letter-navigation--container">
            {prevLetter ? <LetterNavButton prev letter={prevLetter} /> : <div />}
            {nextLetter ? <LetterNavButton next letter={nextLetter} /> : <div />}
        </div>
    )
}