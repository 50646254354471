import { FC, ReactNode, useContext } from "react";
import './letters-nav-bar.css';
import { HomeButton } from "./subcomponents/home-button/home-button";
import { NavBarLogo } from "./subcomponents/nav-bar-logo/nav-bar-logo";
import { AuthContext } from "../auth-provider/auth-provider";
import { SignOutButton } from "./subcomponents/sign-out-button/sign-out-button";
import { useLocation } from "react-router-dom";
import { LettersButton } from "./subcomponents/letters-button/letters-button";

type LettersNavBarProps = {
    extras?: ReactNode
}

export const LettersNavBar: FC<LettersNavBarProps> = ({
    extras,
}) => {

    // router hooks
    const location = useLocation()
    const isRoot = location.pathname.split('/').length < 3

    // context
    const { authenticatedState } = useContext(AuthContext)

    return (
        <div className="letters-nav-bar-container">
            <div className="letters-nav-bar-container--left">
                {isRoot ? <HomeButton /> : <LettersButton />}
            </div>
            <div className="letters-nav-bar-container--center">
                <NavBarLogo />
            </div>
            <div className="letters-nav-bar-container--right">
                {extras}
                {authenticatedState === 'authenticated' && (
                    <SignOutButton />
                )}
            </div>
        </div>
    )
}