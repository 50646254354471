import { FC, useCallback, useContext, useState } from "react";
import { AutoCollapseButton } from "../../../../../auto-collapse-button/auto-collapse-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { LetterPageContext } from "../../../../letter-page.context";
import { Modal } from "antd";
import { service } from "./delete-letter-button.service";
import { useNavigate } from "react-router-dom";

export const DeleteLetterButton: FC = () => {

    // context
    const { letter } = useContext(LetterPageContext)

    // router hooks
    const navigate = useNavigate()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleDeleteLetter = useCallback(async () => {
        setIsLoading(true)
        await service.deleteLetter(letter?.number!)
        navigate('/letters')
        setIsLoading(false)
    }, [letter?.number, navigate])

    const handleButtonClick = useCallback(() => {
        Modal.confirm({
            type: 'warning',
            content: 'Are you sure you want to delete this letter?',
            okText: 'Delete',
            okButtonProps: { danger: true },
            onOk: handleDeleteLetter,
        })
    }, [handleDeleteLetter])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={handleButtonClick}
            loading={isLoading}
        >
            Delete Letter
        </AutoCollapseButton>
    )
}