import { createBrowserRouter } from "react-router-dom";
import { HomeRoute } from "../../routes/home-route/home-route";
import { SignInRoute } from "../../routes/sign-in-route/sign-in-route";
import { LettersRoute } from "../../routes/letters-route/letters-route";
import { LetterRoute } from "../../routes/letter-route/letter-route";
import { LettersChallenge } from "../../views/letters-challenge/letters-challenge";
import { SharedLetterRoute } from "../../routes/shared-letter-route/shared-letter-route";

export const AuthenticatingRouter = createBrowserRouter([
  {
    path: "/",
    element: <HomeRoute />,
  },
  {
    path: "*",
    element: (() => null)(),
  }
])

export const UnauthenticatedRouter = createBrowserRouter([
  {
    path: "/",
    element: <HomeRoute />,
  },
  {
    path: '/shared/:shared_letter_id',
    element: <SharedLetterRoute />,
  },
  {
    path: '*',
    element: <LettersChallenge />
  }
])

export const AuthenticatedRouter = createBrowserRouter([
  {
    path: "/",
    element: <HomeRoute />,
  },
  {
    path: "/sign-in",
    element: <SignInRoute />,
  },
  {
    path: "/letters",
    element: <LettersRoute />
  },
  {
    path: "/letters/:letter_number",
    element: <LetterRoute />
  },
  {
    path: '/shared/:shared_letter_id',
    element: <SharedLetterRoute />,
  },
])