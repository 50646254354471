import { faLinkSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link } from "react-router-dom";
import "./link-does-not-work.css";

export const LinkDoesNotWork: FC = () => {
    return (
        <div className="link-does-not-work--container">
            <h1><FontAwesomeIcon icon={faLinkSlash} />&nbsp;&nbsp;Whoops, that didn't work</h1>
            <p>The author may have stopped sharing the letter. You can always try <Link to="/letters">browsing the letters directly here</Link>.</p>
        </div>
    )
}