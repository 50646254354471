import { FC, useCallback, useState } from "react";
import { ViewContainer } from "../../components/view-container/view-container";
import { LettersNavBar } from "../../components/letters-nav-bar/letters-nav-bar";
import { Alert, Button, Input } from "antd";
import "./sign-in-view.css";
import { FormItem } from "../../components/form-item/form-item";
import { Controller, useForm } from "react-hook-form";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import { firebaseApp } from "../../firebase";

export const SignInView: FC = () => {

    // state
    const [didError, setDidError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form hooks
    const { handleSubmit, formState, control, getValues } = useForm({
        defaultValues: {
            username: '',
            password: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)
        setDidError(false)

        const {
            username,
            password,
        } = getValues()

        const auth = getAuth(firebaseApp)
        try {
            await signInWithEmailAndPassword(auth, username, password)
        } catch {
            setDidError(true)
        }

        setIsLoading(false)
    }, [getValues])

    return (
        <ViewContainer>
            <LettersNavBar />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="sign-in-container">
                    <h1>Sign In</h1>
                    <FormItem label="Username">
                        <Controller
                            control={control}
                            name="username"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="email@example.com"
                                    disabled={isLoading}
                                />
                            )}
                        />
                    </FormItem>
                    <FormItem label="Password">
                        <Controller
                            control={control}
                            name="password"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type="password"
                                    placeholder="••••••••••"
                                    disabled={isLoading}
                                />
                            )}
                        />
                    </FormItem>
                    <Button
                        style={{ marginTop: 12 }}
                        type="primary"
                        htmlType="submit"
                        disabled={!formState.isValid}
                        loading={isLoading}
                    >Sign In</Button>
                    {didError && (
                        <Alert
                            type="warning"
                            description="That didn't work. Please try again."
                        />
                    )}
                </div>
            </form>
        </ViewContainer>
    )
}