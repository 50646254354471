import { DatePicker, Spin } from "antd";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { LetterPageContext } from "../../../../letter-page.context";
import dayjs, { Dayjs } from "dayjs";
import { service } from "./letter-date-picker.service";

export const LetterDatePicker: FC = () => {

    // context
    const { letter, onRefresh } = useContext(LetterPageContext)

    // state
    const [date, setDate] = useState<Dayjs>(dayjs(letter?.date.toDate()))
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // side effects
    useEffect(() => {
        setDate(dayjs(letter?.date.toDate()))
    }, [letter?.date])

    // event handlers
    const handleDateChange = useCallback(async (newDate: Dayjs) => {
        setIsLoading(true)
        setDate(newDate)
        await service.changeDate(letter!.number, newDate.toDate())
        await onRefresh()
        setIsLoading(false)
    }, [letter, onRefresh])

    return (
        <Spin spinning={isLoading}>
            <DatePicker
                value={date}    
                onChange={handleDateChange}
            />
        </Spin>
    )
}