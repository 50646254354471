import { Timestamp, addDoc, collection, doc, getDocs, getFirestore, limit, orderBy, query, setDoc } from "@firebase/firestore"
import { firebaseApp } from "../../../../firebase"

class WriteLetterButtonService {
    public async createLetter(): Promise<number> {
        /* get next letter ID */
        const db = getFirestore(firebaseApp)
        const highestLetterQuery = await getDocs(query(collection(db, 'letters'), orderBy('number', 'desc'), limit(1)))

        const newLetterNumber = highestLetterQuery.docs[0] ? highestLetterQuery.docs[0].data().number + 1 : 1

        /* create the letter */
        const letter = await addDoc(collection(db, 'letters'), {
            number: newLetterNumber,
            title: `Letter #${newLetterNumber}`,
            date: Timestamp.now(),
            isPrivate: true,
            isPublished: false,
            preview: '',
            wordCount: 2,
        })

        /* create the letter contents */
        await setDoc(doc(db, `letterContents/${letter.id}`), {
            content: `<h1>Letter #${newLetterNumber}</h1>`,
        })

        /* return the letter number */
        return newLetterNumber
    }
}

export const service = new WriteLetterButtonService()