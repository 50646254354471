import { FC, useContext } from "react";
import { AuthContext } from "../../../auth-provider/auth-provider";
import "./letter-toolbar.css";
import { EditButton } from "./subcomponents/edit-button/edit-button";
import { LetterPageContext } from "../../letter-page.context";
import { SaveButton } from "./subcomponents/save-button/save-button";
import { PublishButton } from "./subcomponents/publish-button/publish-button";
import { PrivateButton } from "./subcomponents/private-button/private-button";
import { LetterDatePicker } from "./subcomponents/letter-date-picker/letter-date-picker";
import { DeleteLetterButton } from "./subcomponents/delete-letter-button/delete-letter-button";
import { ShareButton } from "./subcomponents/share-button/share-button";

export const LetterToolbar: FC = () => {

    // context
    const { userEntity } = useContext(AuthContext)
    const { letter, isEditing } = useContext(LetterPageContext)

    if (userEntity?.role !== 'writer') return <div style={{ height: 32 }} />;
    if (!letter) return null;

    return (
        <div className="letter-toolbar">
            {isEditing && <SaveButton />}
            <EditButton />
            <PublishButton />
            {!isEditing && (
                <>
                    <PrivateButton />
                    <ShareButton />
                    <DeleteLetterButton />
                </>
            )}
            <LetterDatePicker />
        </div>
    )
}