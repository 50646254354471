import { FC, useCallback, useContext, useEffect, useState } from "react";
import './letter-view.css';
import { useLocation, useSearchParams } from "react-router-dom";
import { LetterContentEntity, LetterEntity } from "../../types/entities";
import { service } from "../../components/letter-page/letter-page.service";
import { AuthContext } from "../../components/auth-provider/auth-provider";
import { LetterPage } from "../../components/letter-page/letter-page";

type LetterViewProps = {
    letterNumber: number
}

export const LetterView: FC<LetterViewProps> = ({
    letterNumber,
}) => {

    // context
    const { userEntity } = useContext(AuthContext)

    // router hooks
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation();

    // state
    const [letter, setLetter] = useState<LetterEntity | null>(null)
    const [letterContent, setLetterContent] = useState<LetterContentEntity | null>(null)

    // event handlers
    const handleRefresh = useCallback(async () => {
        if (!userEntity) return;

        const letter = await service.getLetter(letterNumber, userEntity)
        setLetter(letter)

        if (userEntity.role === 'reader' && letter.isPrivate) {
            setLetterContent(null)
            return;
        }

        const letterContent = await service.getLetterContents(letter.id)

        setLetterContent(letterContent)
    }, [letterNumber, userEntity])

    // side effects
    useEffect(() => {
        handleRefresh()
    }, [handleRefresh, letterNumber])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!letter) {
            document.title = `Letter #${letterNumber}`
            return;
        }
        document.title = `Letter #${letterNumber}: ${letter.title}`;
    }, [letter?.title, letter?.number, letter, letterNumber])

    return (
        <LetterPage
            letter={letter}
            letterContents={letterContent}
            isEditingDefault={searchParams.get('edit') === 'true' ? true : false}
            showNavigation
            onRefresh={handleRefresh}
        />
    )
}