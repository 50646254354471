import { FC } from "react";
import { Link } from "react-router-dom";
import { LetterSignature } from "../../../../components/letter-page/subcomponents/letter-signature/letter-signature";

export const NoteToHer: FC = () => {
    return (
        <div style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto', paddingTop: 48, paddingBottom: 64 }}>
            <h3>Hey AC,</h3>
            <p>I write you a letter every day. At first, I was just writing them in a journal that I wasn't going to share. But now I put them here too.</p>
            <p>I recommend reading "<Link to="/letters/19">Letter 19: Welcome to the letters</Link>" first. That's the first letter I wrote after I published the site. It explains everything and has a helpful list.</p>
            <p>After that maybe just read them in chronological order. Or don't read them at all. All of the letters have rough edges. I didn't originally write them for you to read so please remember that if you do.</p>
            <p>If a letter is locked that's because it's just a little too private to share right now.</p>
            <p>I miss you. Hope you're doing well.</p>
            <LetterSignature />
        </div>
    )
}