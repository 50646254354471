import { FC, useCallback } from "react";
import { LetterEntity } from "../../../../../../types/entities";
import "./letter-nav-button.css"
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

type LetterNavButtonProps = {
    letter: LetterEntity
    next?: boolean
    prev?: boolean
}

export const LetterNavButton: FC<LetterNavButtonProps> = ({
    letter,
    next,
    prev,
}) => {

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleClick = useCallback(() => {
        navigate(`/letters/${letter.number}`)
    }, [letter.number, navigate])

    return (
        <div className={`letter-nav-button ${next && 'next'}`} onClick={handleClick}>
            <span>{prev && <><FontAwesomeIcon icon={faArrowLeft} />&nbsp;</>}<strong>{letter.title}</strong>{next && <>&nbsp;<FontAwesomeIcon icon={faArrowRight} /></>}</span>
            <span className="letter-nav-button--date-indicator">{DateTime.fromJSDate(letter.date.toDate()).toLocaleString(DateTime.DATE_FULL)}</span>
            {next && <span className="letter-nav-button--direction-label">Next</span>}
            {prev && <span className="letter-nav-button--direction-label">Previous</span>}
        </div>
    )
}