import { QueryConstraint, collection, doc, getDoc, getDocs, getFirestore, limit, query, updateDoc, where } from "@firebase/firestore";
import { LetterContentEntity, LetterEntity, UserEntity } from "../../types/entities";
import { firebaseApp } from "../../firebase";

class LetterPageService {
    public async getLetter(letterNumber: number, user: UserEntity): Promise<LetterEntity> {
        const db = getFirestore(firebaseApp)

        const constraints: QueryConstraint[] = [where('number', '==', letterNumber), limit(1)]

        if (user.role === 'reader') {
            constraints.push(where('isPublished', '==', true))
        }

        const docs = await getDocs(query(collection(db, 'letters'), ...constraints))
        const letterDoc = docs.docs[0]
        const letter = letterDoc.data() as LetterEntity;
        letter.id = letterDoc.id

        return letter
    }

    public async getLetterContents(letterId: string): Promise<LetterContentEntity> {
        const db = getFirestore(firebaseApp)

        const letterContentDoc = await getDoc(doc(db, `letterContents/${letterId}`))
        const letterContent = letterContentDoc.data() as LetterContentEntity;

        return letterContent
    }

    public async saveLetterContent(letterNumber: number, payload: { contents: string, preview: string, title: string, wordCount: number }): Promise<void> {
        const db = getFirestore(firebaseApp)
        const docs = await getDocs(query(collection(db, 'letters'), where('number', '==', letterNumber), limit(1)))
        const letterDoc = docs.docs[0]
        await updateDoc(letterDoc.ref, {
            title: payload.title,
            preview: payload.preview,
            wordCount: payload.wordCount,
        })
        const letterContentDoc = await getDoc(doc(db, `letterContents/${letterDoc.id}`))
        await updateDoc(letterContentDoc.ref, {
            content: payload.contents,
        })
    }
}

export const service = new LetterPageService()