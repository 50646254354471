import { useMediaQuery } from "@react-hook/media-query";
import { FC } from "react";
import './nav-bar-logo.css';

export const NavBarLogo: FC = () => {

    // responsive hooks
    const isNotMini = useMediaQuery('only screen and (min-width: 400px)')

    return (
        <span className="nav-bar-logo">
            {isNotMini ? 'garrettcharliehill' : 'ch'}
        </span>
    )
}