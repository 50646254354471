import { FC, useCallback, useContext } from "react";
import { LetterEntity } from "../../../../types/entities";
import "./letter-item.css";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { AuthContext } from "../../../../components/auth-provider/auth-provider";

type LetterItemProps = {
    letter: LetterEntity
}

export const LetterItem: FC<LetterItemProps> = ({
    letter,
}) => {
    
    // context
    const { userEntity } = useContext(AuthContext)

    // router hooks
    const navigate = useNavigate()

    // constants
    const displayPrivate = userEntity?.role === 'reader' && letter.isPrivate

    // event handlers
    const handleItemClick = useCallback(() => {
        navigate(`/letters/${letter.number}`)
    }, [letter.number, navigate])

    return (
        <div className={`letter-item--container ${displayPrivate && 'letter-item--is-private'}`} onClick={handleItemClick}>
            <span className="letter-item--number">{DateTime.fromJSDate(letter.date.toDate()).toLocaleString(DateTime.DATE_FULL)}</span>
            <h2>{letter.isPrivate && <FontAwesomeIcon style={{ marginRight: 8 }} icon={faLock} />}{letter.title}</h2>
            <p>{letter.preview || 'Letter is empty.'}</p>
            <div>
                <span className="letter-item--number">Letter #{letter.number}</span>
            </div>
        </div>
    )
}