import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { LetterPageContext } from "./letter-page.context";
import { ViewContainer } from "../view-container/view-container";
import { LettersNavBar } from "../letters-nav-bar/letters-nav-bar";
import { LetterToolbar } from "./subcomponents/letter-toolbar/letter-toolbar";
import { LetterContentEntity, LetterEntity } from "../../types/entities";
import { service } from "./letter-page.service";
import { DateTime } from "luxon";
import ReactQuill from "react-quill";
import { LetterSignature } from "./subcomponents/letter-signature/letter-signature";
import { LetterLocked } from "./subcomponents/letter-locked/letter-locked";
import { LetterNavigation } from "./subcomponents/letter-navigation/letter-navigation";
import { AuthContext } from "../auth-provider/auth-provider";

type LetterPageProps = {
    letter: LetterEntity | null
    letterContents: LetterContentEntity | null
    isEditingDefault?: boolean
    showNavigation?: boolean
    onRefresh: () => Promise<void>
}

export const LetterPage: FC<LetterPageProps> = ({
    letter,
    letterContents,
    isEditingDefault = false,
    showNavigation,
    onRefresh,
}) => {

    // context
    const { userEntity } = useContext(AuthContext)

    // refs
    const containerRef = useRef<HTMLDivElement>(null)

    // state
    const [isEditing, setIsEditing] = useState<boolean>(isEditingDefault)
    const [contents, setContents] = useState<string>('');
    
    // event handlers
    const handleSave = useCallback(async () => {
        if (!letter) return;

        const titleElement = containerRef.current?.querySelector('h1')
        const title = titleElement?.innerText || `Letter #${letter?.number}`;

        const editorElement = containerRef.current?.querySelector('.ql-editor') as HTMLDivElement | null
        const editorInnerText = editorElement?.innerText || '';

        let preview = `${editorInnerText.slice(0, 255)}${editorInnerText.length > 255 ? '...' : ''}`

        if (preview.startsWith(title)) {
            preview = preview.replace(title, '').trimStart()
        }

        await service.saveLetterContent(letter.number, {
            title,
            contents,
            preview,
            wordCount: editorInnerText.split(' ').length,
        })
        await onRefresh()
        setIsEditing(false)
    }, [contents, letter, onRefresh])

    const handleDiscard = useCallback(() => {
        setContents(letterContents?.content ?? '')
    }, [letterContents?.content])

    // side effects
    useEffect(() => {
        setContents(letterContents?.content ?? '')
    }, [letterContents?.content])

    return (
        <LetterPageContext.Provider value={{
            letter,
            letterContents,
            isEditing,
            onIsEditingChange: setIsEditing,
            onSave: handleSave,
            onRefresh,
            onDiscard: handleDiscard,
        }}>
            <ViewContainer>
                <LettersNavBar />
                <LetterToolbar />
                <div ref={containerRef} className={`editor-container ${!isEditing ? 'readonly' : ''}`}>
                    <h3>{letter && DateTime.fromJSDate(letter.date.toDate()).toLocaleString(DateTime.DATE_FULL)}</h3>
                    {letterContents && (
                        <>
                            <ReactQuill
                                theme="snow"
                                value={contents}
                                onChange={setContents}
                                readOnly={!isEditing}
                            />
                            {contents && <LetterSignature />}
                        </>
                    )}
                    {userEntity?.role === 'reader' && letter?.isPrivate && (
                        <LetterLocked />
                    )}
                    <div style={{ height: 48 }} />
                    {showNavigation && <LetterNavigation />}
                </div>
            </ViewContainer>
        </LetterPageContext.Provider>
    )
}