import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useContext } from "react";
import { LetterPageContext } from "../../letter-page.context";
import "./letter-locked.css"

export const LetterLocked: FC = () => {

    // context
    const { letter } = useContext(LetterPageContext)

    return (
        <div className="letter-locked--container">
            <h1>{letter?.title}</h1>
            <div className="letter-locked--inner">
                <h2><FontAwesomeIcon icon={faLock} />&nbsp;&nbsp;This letter is locked</h2>
                <p>If a letter is locked that's because it's just a little too private to share right now.</p>
            </div>
        </div>
    )
}