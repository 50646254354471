import { getFirestore, getDocs, query, collection, where, limit, updateDoc } from "@firebase/firestore"
import { firebaseApp } from "../../../../../../firebase"

class PrivateButtonService {
    public async makeLetterPrivate(letterNumber: number): Promise<void> {
        const db = getFirestore(firebaseApp)
        const docs = await getDocs(query(collection(db, 'letters'), where('number', '==', letterNumber), limit(1)))
        const letterDoc = docs.docs[0]
        await updateDoc(letterDoc.ref, {
            isPrivate: true,
        })
    }

    public async makeLetterPublic(letterNumber: number): Promise<void> {
        const db = getFirestore(firebaseApp)
        const docs = await getDocs(query(collection(db, 'letters'), where('number', '==', letterNumber), limit(1)))
        const letterDoc = docs.docs[0]
        await updateDoc(letterDoc.ref, {
            isPrivate: false,
        })
    }
}

export const service = new PrivateButtonService()