import { FC, useCallback, useState } from "react";
import { AutoCollapseButton } from "../../../../../auto-collapse-button/auto-collapse-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/pro-regular-svg-icons";
import "./share-button.css";
import { SharingModal } from "./subcomponents/sharing-modal/sharing-modal";

export const ShareButton: FC = () => {

    // state
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    // event handlers
    const handleShareButtonClick = useCallback(() => {
        setModalOpen(true)
    }, [])

    const handleCloseModal = useCallback(() => {
        setModalOpen(false)
    }, [])

    return (
        <>
            <AutoCollapseButton
                icon={<FontAwesomeIcon icon={faShare} />}
                onClick={handleShareButtonClick}
            >
                Share
            </AutoCollapseButton>
            <SharingModal
                isOpen={modalOpen}
                onClose={handleCloseModal}
            />
        </>
    )
}