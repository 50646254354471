import { createContext } from "react"
import { LetterContentEntity, LetterEntity } from "../../types/entities"

type LetterPageContextType = {
    letter: LetterEntity | null,
    letterContents: LetterContentEntity | null,
    isEditing: boolean
    onIsEditingChange: (isEditing: boolean) => void
    onSave: () => Promise<void>,
    onRefresh: () => Promise<void>,
    onDiscard: () => void,
}

export const LetterPageContext = createContext<LetterPageContextType>({
    letter: null,
    letterContents: null,
    isEditing: false,
    onIsEditingChange: () => {},
    onSave: async () => {},
    onRefresh: async () => {},
    onDiscard: () => {},
})