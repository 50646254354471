import { addDoc, collection, getDoc, getFirestore } from "@firebase/firestore";
import { SharedLetterEntity } from "../../../../../../../../types/entities";
import { firebaseApp } from "../../../../../../../../firebase";

class SharingModalService {
    public async createSharedLetter(letterId: string, label: string): Promise<SharedLetterEntity> {
        const db = getFirestore(firebaseApp)
        const sharedLetterRef = await addDoc(collection(db, 'sharedLetters'), {
            label,
            letterId,
            usageCount: 0,
            enabled: true,
        })

        const sharedLetterSnapshot = await getDoc(sharedLetterRef)

        return {
            id: sharedLetterSnapshot.id,
            ...sharedLetterSnapshot.data(),
        } as SharedLetterEntity
    }
}

export const service = new SharingModalService()