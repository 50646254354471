import { FC, ReactNode } from "react";
import "./form-item.css";

type FormItemProps = {
    label: string
    children: ReactNode
}

export const FormItem: FC<FormItemProps> = ({
    label,
    children,
}) => {
    return (
        <div className="form-item-container">
            <span>{label}</span>
            {children}
        </div>
    )
}