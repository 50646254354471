import { useMediaQuery } from "@react-hook/media-query";
import { Button, ButtonProps } from "antd";
import { FC, ReactNode } from "react";

type AutoCollapseButtonProps = {
    children: ReactNode
    icon: ReactNode
} & ButtonProps

export const AutoCollapseButton: FC<AutoCollapseButtonProps> = ({
    children,
    icon,
    ...rest
}) => {

    // media queries
    const isNotMobile = useMediaQuery('only screen and (min-width: 500px)')

    return (
        <Button {...rest} icon={icon} size={isNotMobile ? 'middle' : 'large'}>
            {isNotMobile ? children : null}
        </Button>
    )
}