import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AutoCollapseButton } from "../../../../../auto-collapse-button/auto-collapse-button";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LetterPageContext } from "../../../../letter-page.context";

export const SaveButton: FC = () => {

    // context
    const { onSave, isEditing } = useContext(LetterPageContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(async () => {
        setIsLoading(true)
        await onSave()
        setIsLoading(false)
    }, [onSave])

    // side effects
    useEffect(() => {
        if (!isEditing) return;

        const onKeyDown = (event: KeyboardEvent) => {
            if (!event.metaKey) return;
            if (event.key !== 's') return;
            event.preventDefault()
            handleButtonClick()
        }

        document.body.addEventListener('keydown', onKeyDown)
        return () => {
            document.body.removeEventListener('keydown', onKeyDown)
        }
    }, [handleButtonClick, isEditing])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon icon={faSave} />}
            type="primary"
            onClick={handleButtonClick}
            loading={isLoading}
        >
            Save
        </AutoCollapseButton>
    )
}