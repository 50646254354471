import { FC, useContext, useEffect, useState } from "react";
import './letters-view.css';
import { LettersNavBar } from "../../components/letters-nav-bar/letters-nav-bar";
import { ViewContainer } from "../../components/view-container/view-container";
import { LetterEntity } from "../../types/entities";
import { QueryConstraint, collection, getDocs, getFirestore, orderBy, query, where } from "@firebase/firestore";
import { firebaseApp } from "../../firebase";
import { AuthContext } from "../../components/auth-provider/auth-provider";
import { WriteLetterButton } from "./subcomponents/write-letter-button/write-letter-button";
import { LetterItem } from "./subcomponents/letter-item/letter-item";
import { Link } from "react-router-dom";
import { LetterSignature } from "../../components/letter-page/subcomponents/letter-signature/letter-signature";
import { NoteToHer } from "./subcomponents/note-to-her/note-to-her";

export const LettersView: FC = () => {

    // context
    const { userEntity } = useContext(AuthContext)

    // state
    const [letters, setLetters] = useState<LetterEntity[] | null>(null)

    // side effects
    useEffect(() => {
        if (!userEntity) return;

        const db = getFirestore(firebaseApp)

        const constraints: QueryConstraint[] = [
            orderBy('number', 'desc')
        ]

        if (userEntity?.role === 'reader') {
            constraints.push(where('isPublished', '==', true))
        }

        getDocs(query(collection(db, 'letters'), ...constraints)).then(result => {
            setLetters(result.docs.map(doc => ({ id: doc.id, ...doc.data() }) as LetterEntity))
        })
    }, [userEntity, userEntity?.role])

    useEffect(() => {
        document.title = 'All Letters';
    }, [])

    return (
        <ViewContainer>
            <LettersNavBar extras={userEntity?.role === 'writer' ? (
                <WriteLetterButton />
            ) : null} />
            {userEntity?.role === 'reader' && <NoteToHer />}
            <h1>All Letters</h1>
            <div className="letters-list-view">
                {letters?.map(letter => (
                    <LetterItem key={letter.id} letter={letter} />
                ))}
            </div>
        </ViewContainer>
    )
}