import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBbCNcUdy87IUok04Tuph2QeqJ7a-KnIMM",
  authDomain: "garrettcharliehill-letters.firebaseapp.com",
  projectId: "garrettcharliehill-letters",
  storageBucket: "garrettcharliehill-letters.appspot.com",
  messagingSenderId: "596371980901",
  appId: "1:596371980901:web:f2703b002d57476b543c8c",
};

// Initialize Firebase
export const firebaseApp = initializeApp({
  ...firebaseConfig,
});