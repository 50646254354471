import { Input, Modal } from "antd";
import { ChangeEvent, FC, FormEvent, useCallback, useContext, useState } from "react";
import { FormItem } from "../../../../../../../form-item/form-item";
import "./sharing-modal.css";
import { SharedLetterEntity } from "../../../../../../../../types/entities";
import { service } from "./sharing-modal.service";
import { LetterPageContext } from "../../../../../../letter-page.context";

type SharingModalProps = {
    isOpen: boolean
    onClose: () => void
}

export const SharingModal: FC<SharingModalProps> = ({
    isOpen,
    onClose,
}) => {

    // context
    const { letter } = useContext(LetterPageContext)

    // state
    const [label, setLabel] = useState<string>('')
    const [sharedLetter, setSharedLetter] = useState<SharedLetterEntity | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // constants
    const linkUrl = `https://garrettcharliehill.com/shared/${sharedLetter?.id}`

    // event handlers
    const handleOk = useCallback(async () => {
        if (sharedLetter) {
            navigator.clipboard.writeText(linkUrl)
            onClose()
            return;
        }

        setIsLoading(true)

        const newSharedLetter = await service.createSharedLetter(
            letter!.id,
            label,
        )

        setSharedLetter(newSharedLetter)
        setIsLoading(false)
    
    }, [label, letter, linkUrl, onClose, sharedLetter])

    const handleCancel = useCallback(() => {
        setLabel('')
        setSharedLetter(null)
        onClose()
    }, [onClose])

    const handleFormSubmit = useCallback((event: FormEvent) => {
        event.preventDefault()
        handleOk()
    }, [handleOk])

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value ?? '')
    }, [])

    return (
        <Modal
            open={isOpen}
            title="Get Sharing Link"
            okText={sharedLetter ? 'Copy & Close' : 'Generate Link'}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText={sharedLetter ? 'Close' : 'Cancel'}
            okButtonProps={{
                disabled: !label,
                loading: isLoading,
            }}
        >
            <form onSubmit={handleFormSubmit} className="sharing-modal--inner-container">
                <FormItem label="Link Name">
                    <Input
                        placeholder="e.g. Notes on letting you go - for AC"
                        onChange={handleChange}
                        readOnly={!!sharedLetter}
                        disabled={!!sharedLetter}
                    />
                </FormItem>
                {sharedLetter && (
                    <FormItem label="Sharing Link">
                        <Input readOnly value={linkUrl} />
                    </FormItem>
                )}
            </form>
        </Modal>
    )
}