import { faRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback } from "react";
import { AutoCollapseButton } from "../../../auto-collapse-button/auto-collapse-button";
import { getAuth, signOut } from "@firebase/auth";
import { firebaseApp } from "../../../../firebase";
import { useNavigate } from "react-router-dom";

export const SignOutButton: FC = () => {

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleSignOutClick = useCallback(async () => {
        navigate('/')
        const auth = getAuth(firebaseApp)
        await signOut(auth)
    }, [navigate])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon icon={faRightFromBracket} />}
            onClick={handleSignOutClick}
        >
            Sign Out
        </AutoCollapseButton>
    )
}