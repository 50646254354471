import { FC } from "react";
import { useParams } from "react-router-dom";
import { LetterView } from "../../views/letter-view/letter-view";

export const LetterRoute: FC = () => {

    // router hooks
    const { letter_number: letterNumber } = useParams() as { letter_number: string }

    return (
        <LetterView
            letterNumber={Number(letterNumber)}
        />
    )
}