import { FC, useCallback } from "react";
import { AutoCollapseButton } from "../../../auto-collapse-button/auto-collapse-button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/pro-regular-svg-icons";

export const HomeButton: FC = () => {

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleHomeClick = useCallback(() => {
        navigate('/')
    }, [navigate])

    return (
        <AutoCollapseButton
            onClick={handleHomeClick}
            icon={<FontAwesomeIcon icon={faHouse} />}
        >
            Home
        </AutoCollapseButton>
    )
}