import { getFirestore, getDocs, query, collection, where, limit, deleteDoc, doc } from "@firebase/firestore"
import { firebaseApp } from "../../../../../../firebase"

class DeleteLetterButtonService {
    public async deleteLetter(letterNumber: number): Promise<void> {
        const db = getFirestore(firebaseApp)
        const docs = await getDocs(query(collection(db, 'letters'), where('number', '==', letterNumber), limit(1)))
        const letterDoc = docs.docs[0]
        await deleteDoc(letterDoc.ref)
        await deleteDoc(doc(db, `letterContents/${letterDoc.id}`))
    }
}

export const service = new DeleteLetterButtonService()