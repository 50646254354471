import { FC, useCallback, useEffect } from "react";
import './home-view.css';
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { LetterSignature } from "../../components/letter-page/subcomponents/letter-signature/letter-signature";

export const HomeView: FC = () => {

    // router hooks
    const navigate = useNavigate()

    // side effects
    useEffect(() => {
        document.title = 'garrettcharliehill'
    }, [])

    // event handlers
    const handleContinueClick = useCallback(() => {
        navigate('/letters')
    }, [navigate])

    return (
        <div className="home-view-container">
            <h1>Hello,</h1>
            <p>I write a letter to you every day.</p>
            <p>At first, I was just writing them in a journal that I wasn't going to share.</p>
            <p>But after I wrote a letter about letting you go, I realized I needed a way to share at least that one with you.</p>
            <p>I didn't want to text it to you so I made you this website.</p>
            <p>Either way, I miss you. Hope you're doing well.</p>
            <LetterSignature />
            <div className="home-view--footer">
                <Button
                    block
                    type="primary"
                    onClick={handleContinueClick}
                >
                    Go to letters
                </Button>
            </div>
        </div>
    )
}