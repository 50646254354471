import { FC, useCallback, useContext } from "react";
import { AutoCollapseButton } from "../../../../../auto-collapse-button/auto-collapse-button";
import { faPencil, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LetterPageContext } from "../../../../letter-page.context";

export const EditButton: FC = () => {

    // context
    const { isEditing, onIsEditingChange, onDiscard } = useContext(LetterPageContext)

    // event handlers
    const handleButtonClick = useCallback(() => {
        onIsEditingChange(!isEditing)
        if (!!isEditing) onDiscard()
    }, [isEditing, onDiscard, onIsEditingChange])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon
            icon={isEditing ? faTimes : faPencil} />}
            onClick={handleButtonClick}
        >
            {isEditing ? 'Cancel' : 'Edit'}
        </AutoCollapseButton>
    )
}