import { FC } from "react";
import { SharedLetterView } from "../../views/shared-letter-view/shared-letter-view";
import { useParams } from "react-router-dom";

export const SharedLetterRoute: FC = () => {

    // router hooks
    const { shared_letter_id: sharedLetterId } = useParams() as { shared_letter_id: string }

    return (
        <SharedLetterView
            sharedLetterId={sharedLetterId}
        />
    )
}