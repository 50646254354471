import './App.css';
import 'react-quill/dist/quill.snow.css';
import './styles/quill-overrides.css';
import './styles/quill-styling.css';
import './firebase';
import { ConfigProvider } from 'antd';
import { AuthProvider } from './components/auth-provider/auth-provider';
import { AppRouter } from './components/app-router/app-router';

function App() {

  return (
    <AuthProvider>
      <ConfigProvider theme={{ token: { colorPrimary: 'green', fontFamily: 'New York' } }}>
        <AppRouter />
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
