import { FC, useCallback, useContext, useState } from "react";
import { AutoCollapseButton } from "../../../../../auto-collapse-button/auto-collapse-button";
import { faBan, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LetterPageContext } from "../../../../letter-page.context";
import { service } from "./publish-button.service";

export const PublishButton: FC = () => {

    // context
    const { letter, onSave, onRefresh } = useContext(LetterPageContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(async () => {
        setIsLoading(true)
        await onSave()
        if (letter?.isPublished) {
            await service.unpublishLetter(letter!.number)
        } else {
            await service.publishLetter(letter!.number)
        }
        await onRefresh()
        setIsLoading(false)
    }, [letter, onRefresh, onSave])

    return (
        <AutoCollapseButton
            icon={<FontAwesomeIcon
            icon={letter?.isPublished ? faBan : faPaperPlane} />}
            onClick={handleButtonClick}
            loading={isLoading}
        >
            {letter?.isPublished ? 'Unpublish' : 'Publish'}
        </AutoCollapseButton>
    )
}